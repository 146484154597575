<template>
  <div>
    <b-row class="vh-100 d-flex flex-column justify-content-center">
      <b-col xl="7" offset-xl="1">
        <b-row class="flex-column">
          <b-col cols="8" sm="6" md="4" xl="6">
            <b-img
              :src="require('@/assets/svg/myHubcast-logo.svg')"
              alt="radioshop logo"
              class="w-100"
            >
            </b-img>
          </b-col>
          <b-col class="mt-5">
            <h-card>
              <div class="px-3">
                <h2 class="h2 my-2 font-weight-bold">
                  {{ $t('login.connectionTitle') }}
                </h2>
                <div class="sep mt-3"></div>
                <div v-if="chooseAccountScope" class="mt-4">
                  <div
                    v-if="$store.state.user.user.visibleIdentities.length > 1"
                  >
                    <span class="choose-title">
                      {{ $t('login.identityChoice') }}
                    </span>
                    <span class="choose-title">
                      {{ $t('login.identityChoice') }}
                    </span>
                    <h-multi-select-account-scope
                      class="mt-2"
                      v-model="selectedIdentity"
                      placeholder="Selectionner une identité..."
                      left-icon="file-earmark-person"
                      :options="$store.state.user.user.visibleIdentities"
                    ></h-multi-select-account-scope>
                  </div>
                  <div
                    class="mt-3"
                    v-if="
                      $store.state.user.user.visibleBrands.length > 1 &&
                        selectedIdentity !== null
                    "
                  >
                    <span class="choose-title">
                      {{ $t('login.brandChoice') }}
                    </span>
                    <h-multi-select-account-scope
                      class="mt-2"
                      v-model="selectedBrand"
                      :disabled="brandLoading"
                      :placeholder="$t('login.selectBrand')"
                      left-icon="person-check-fill"
                      :options="availableBrands"
                    ></h-multi-select-account-scope>
                  </div>
                  <div
                    class="d-flex flex-column align-items-center mt-5 mb-3"
                    v-if="this.selectedBrand && this.selectedIdentity"
                  >
                    <h-button
                      blue
                      type="submit"
                      @click="connectUserScope"
                      :disabled="
                        (!formValid && !changeValue) ||
                          connexionLoading ||
                          !selectedBrand.id
                      "
                    >
                      <template v-if="connexionLoading">
                        <b-spinner small class="mr-2"></b-spinner>
                        {{ $t('login.loading') }}
                        {{ $t('login.loading') }}
                      </template>
                      <template v-else>
                        <b-icon icon="person" class="mr-2"></b-icon
                        >{{ $t('login.connection') }}
                      </template>
                    </h-button>
                  </div>
                </div>
                <form
                  v-else
                  class="mt-4"
                  @submit.prevent="logUser"
                  autocomplete="off"
                >
                  <h-input
                    v-model.trim="login"
                    :label="$t('login.login')"
                    :errorState="!formValid"
                    @input="changeValue = true"
                  ></h-input>
                  <h-input
                    class="mt-3"
                    v-model.trim="password"
                    :errorState="!formValid"
                    @input="changeValue = true"
                    :label="$t('login.password')"
                    type="password"
                  ></h-input>
                  <!-- <a href="#">
                    <div class="mt-2 secondary-text mt-2 cursor-pointer">
                      {{ $t('login.forgetPassword') }}
                    </div>
                  </a> -->
                  <div class="d-flex flex-column align-items-center mt-5 mb-3">
                    <h-button
                      blue
                      type="submit"
                      :disabled="
                        (!formValid && !changeValue) || connexionLoading
                      "
                    >
                      <template v-if="connexionLoading">
                        <b-spinner small class="mr-2"></b-spinner>
                        {{ $t('login.loading') }}
                      </template>
                      <template v-else>
                        <b-icon icon="person" class="mr-2"></b-icon
                        >{{ $t('login.connection') }}
                      </template>
                    </h-button>
                  </div>
                  <!-- <div class="text-center secondary-text mt-3">
                    Pas encore inscrit ?
                    <router-link :to="{ name: 'register' }" class="register">
                      S'inscrire
                    </router-link>
                  </div> -->
                </form>
              </div>
            </h-card>
            <b-row class="mt-4">
              <b-col class="d-flex justify-content-center">
                <HLocaleChanger />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState, mapGetters } from 'vuex'
import userService from '@/services/userService.js'
export default {
  data() {
    return {
      showPassword: false,
      connexionLoading: false,
      brandLoading: false,
      login: '',
      password: '',
      formValid: true,
      changeValue: false,
      chooseAccountScope: false,
      selectedIdentity: null,
      selectedBrand: null,
      availableBrands: [],
    }
  },
  validations: {
    login: { required },
    password: { required },
  },
  methods: {
    logUser() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.formValid = false
      } else {
        this.logSubmit()
      }
    },
    async logSubmit() {
      this.connexionLoading = true
      const credentials = {
        login: this.login,
        password: this.password,
      }
      try {
        const res = await userService.logUser(credentials)
        this.$store.dispatch('user/_setUser', res.data)
        const { visibleBrands, visibleIdentities } = this.$store.state.user.user

        if (visibleBrands.length > 1 || visibleIdentities.length > 1) {
          this.chooseAccountScope = true
          if (visibleIdentities.length === 1) {
            this.selectedIdentity = visibleIdentities[0]
          }
        } else if (
          visibleBrands.length === 0 ||
          visibleIdentities.length === 0
        ) {
          this.$toast.warning(this.$t('login.errorNoBrand'))
          this.$toast.warning(this.$t('login.errorNoBrand'))
        } else {
          this.checkAutorizationAndPush()
        }
      } catch (err) {
        this.$toast.error(this.$t('login.errorCredentials'))
        this.$toast.error(this.$t('login.errorCredentials'))
        this.connexionLoading = false
        this.formValid = false
        this.changeValue = false
      } finally {
        this.connexionLoading = false
      }
    },
    checkAutorizationAndPush() {
      if (this.authorizedRoleUser) {
        if (this.selectedBrand && this.selectedBrand.id) {
          const visibleSites = this.user.visibleSites.filter(
            (site) => site.idFK === this.selectedBrand.id
          )
          this.$store.dispatch('user/_setVisibleSites', visibleSites)
        } else
          this.$store.dispatch('user/_setVisibleSites', this.user.visibleSites)

        if (this.isSingleSite)
          this.$router.push({
            name: 'player',
            params: { id: this.singleVisibieSite },
          })
        else this.$router.push({ name: 'players' })
        this.$toast.clear()
        window.scrollTo(0, 0)
      } else {
        this.$toast.warning(this.$t('login.errorNotAllowed'))
        this.$toast.warning(this.$t('login.errorNotAllowed'))
        this.connexionLoading = false
        this.formValid = false
        this.changeValue = false
      }
    },
    connectUserScope() {
      this.connexionLoading = true
      if (this.selectedIdentity)
        this.$store.commit('user/SET_USER_IDENTITY', this.selectedIdentity)
      if (this.selectedBrand)
        this.$store.commit('user/SET_USER_BRAND', this.selectedBrand)
      this.checkAutorizationAndPush()
    },
  },
  watch: {
    async selectedIdentity() {
      if (this.selectedIdentity) {
        this.brandLoading = true
        this.selectedBrand = []
        const identityBrands = await userService.getIdentityBrands(
          this.selectedIdentity.id
        )
        const userBrands = this.$store.state.user.user.visibleBrands
        const filteredBrands = identityBrands.data.filter((allbrands) =>
          userBrands.some(
            (userAvailableBrands) => allbrands.id === userAvailableBrands.id
          )
        )

        this.availableBrands = filteredBrands
        this.brandLoading = false
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    ...mapGetters({
      authorizedRoleUser: 'user/authorizedRoleUser',
      isSingleSite: 'user/isSingleSite',
      singleVisibieSite: 'user/singleVisibieSite',
    }),
  },
  mounted() {
    fetch('./version.json')
      .then((res) => res.json())
      .then((vJson) => {
        if (
          localStorage.getItem('lastUpdateStore') &&
          localStorage.getItem('lastUpdateStore') !== vJson.lastUpdate
        ) {
          localStorage.clear()
          localStorage.setItem('lastUpdateStore', vJson.lastUpdate)
          this.$router.go()
        }
        localStorage.setItem('lastUpdateStore', vJson.lastUpdate)
      })
  },
}
</script>

<style lang="scss" scoped>
a:hover {
  color: initial;
}
a.register:hover {
  color: $primary;
}
.choose-title {
  font-size: 16px;
  color: #737e96;
  font-weight: 600;
}
</style>
